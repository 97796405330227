console.log('global index.js loaded')

if (document.querySelector('.listing-immo__container')) {
  const cardImmo = document.querySelectorAll('.listing-immo__container .listing-immo__container__card')
  if (cardImmo.length > 0) {
    cardImmo.forEach((card) => {
      const dropdownBtn = card.querySelector('button.dropdown')
      const dropdownList = card.querySelector('.dropdown__list')
      dropdownBtn.addEventListener('click', () => {
        dropdownList.classList.toggle('show')
        dropdownBtn.classList.toggle('active')
      })
    })
  }

  const rangeMin = document.getElementById('superficie_rangeMin');
  const rangeMax = document.getElementById('superficie_rangeMax');
  const sliderRange = document.querySelector('.slider-range');
  const minValue = document.getElementById('superficie_minValue');
  const maxValue = document.getElementById('superficie_maxValue');
  const maxSizeCustom = Number(document.querySelector('.range-values').dataset.maxsize)

  const updateSlider = () => {
    const min = parseInt(rangeMin.value);
    const max = parseInt(rangeMax.value);

    if (min > max - 10) {
      rangeMin.value = max - 10;
    }
    if (max < min + 10) {
      rangeMax.value = min + 10;
    }

    const percentMin = (rangeMin.value / rangeMin.max) * 100;
    const percentMax = (rangeMax.value / rangeMax.max) * 100;

    sliderRange.style.left = percentMin + '%';
    sliderRange.style.right = 100 - percentMax + '%';

    minValue.textContent = ((rangeMin.value / 100) * maxSizeCustom).toFixed(0) + ' M²';
    if(rangeMax.value === '100') {
      maxValue.textContent = ((rangeMax.value / 100) * maxSizeCustom).toFixed(0) + ' M² et plus';
    } else {
      maxValue.textContent = ((rangeMax.value / 100) * maxSizeCustom).toFixed(0) + ' M²';
    }
  };

  rangeMin.addEventListener('input', updateSlider);
  rangeMax.addEventListener('input', updateSlider);

  updateSlider();
}
